<div class="app">
  <div class="app__inner" [class.anim--in]="loading$()" [@fadeAnimation]="outlet.activatedRouteData">
    @if (loaded$()) {
      <app-topbar
        (toggleOverlay)="toggleMenu()"
        (closeOverlay)="closeMenu()"
        (openOverlay)="openMenu()"
        [menuActive]="menuOpen$()"
        [menu]="('main-menu' | fromMenu)?.error ? [] : ('main-menu' | fromMenu)"
        [metaMenu]="('meta-menu' | fromMenu)?.error ? [] : ('meta-menu' | fromMenu)"
        (openCookiePreferences)="openCookiePreferences()"
        [showProgramCTAButton]="showProgramCTAButton$()"
      >
        <app-partner-list partner-list [partners]="activePortal$()?.partners"></app-partner-list>
        <app-social-channel-buttons social-list [channels]="activePortal$()?.socialChannels || []"></app-social-channel-buttons>
      </app-topbar>
    }

    <div class="app__router" id="content">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

    @if (loaded$()) {
      <app-footer
        (openCookiePreferences)="openCookiePreferences()"
        [domain]="domainName"
        [menu]="('footer-menu' | fromMenu)?.error ? [] : ('footer-menu' | fromMenu)"
        [socials]="activePortal$()?.socialChannels || []"
      ></app-footer>
    }
  </div>

  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>

  @if (loaded$()) {
    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    ></fstr-cookie-notice>
  }
</div>

@if (testGridEnabled) {
  <app-grid-overlay></app-grid-overlay>
}
