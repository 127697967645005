import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as socialActions from '../actions/social-channels.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SocialChannelsService } from '../../services';

export const LoadSocialChannels$ = createEffect(
  (actions$ = inject(Actions), service = inject(SocialChannelsService)) => {
    return actions$.pipe(
      ofType(socialActions.LoadSocialChannels),
      switchMap(() =>
        service.getSocialChannels().pipe(
          map(channels => socialActions.LoadSocialChannelsSuccess({ channels })),
          catchError(error => of(socialActions.LoadSocialChannelsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
