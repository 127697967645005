import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/social-channels.action';
import { SocialChannel } from '../../models';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface SocialChannelsState {
  entities: { [id: number]: SocialChannel };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: SocialChannelsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const socialChannelsReducer = createReducer(
  initialState,
  on(actions.LoadSocialChannels, (state: SocialChannelsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.LoadSocialChannelsSuccess, (state: SocialChannelsState, { channels }) => {
    const entities = ToEntities(channels, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadSocialChannelsFail, (state: SocialChannelsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getSocialChannelEntities = (state: SocialChannelsState) => state.entities;
export const getSocialChannelsLoading = (state: SocialChannelsState) => state.loading;
export const getSocialChannelsLoaded = (state: SocialChannelsState) => state.loaded;
