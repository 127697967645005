import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { reducers, effects, featureKey, metaReducers } from './store';
import { PortalsService } from './services';

export function ProvidePortals(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(featureKey, reducers, {
      metaReducers: metaReducers,
    }),
    provideEffects(effects),
    PortalsService,
  ]);
}
