<ul class="partner-list">
  @for (partner of partners(); track partner.id) {
    <li class="partner-list__item">
      <a [href]="partner.websiteUrl" [title]="partner.title" target="_blank" rel="noopener">
        <!--<img [alt]="partner.title" loading="lazy" [src]="partner.logoUrl | resize : 160 : 0 : 'center' : 'pad' : 'png'" />-->

        <!--<img [ngSrc]="partner.logoUrl" [alt]="partner.title" />-->
        <img [src]="partner.logoUrl" [alt]="partner.title" />
      </a>
    </li>
  }
</ul>
