import { isPlatformServer } from "@angular/common";
import {
  Component,
  Optional,
  Inject,
  InjectionToken,
  PLATFORM_ID,
} from "@angular/core";
import { RESPONSE } from "../express.tokens";
import { Router, RouterLink } from "@angular/router";
import { Response } from "express";
import { FromDictionaryPipe } from "@teamfoster/sdk/dictionary-ngrx";
import { IconModule } from "@teamfoster/sdk/icon";

@Component({
  selector: "app-404",
  standalone: true,
  imports: [FromDictionaryPipe, RouterLink, IconModule],
  styles: [
    `
      :host {
        display: flex;
        overflow: hidden;
        height: 100vh;
        align-items: center;
        justify-content: center;
      }
      .status-code {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50vw;
        text-align: center;
        line-height: 0.5;
        opacity: 0.15;
        //opacity: 0.5;
        z-index: -1;
        font-weight: 700;
        text-transform: uppercase;
        text-wrap: nowrap;
      }
    `,
  ],
  template: `
    <article class="error-404 section-inset-y">
      <div class="container">
        <h1 class="text-max-width-md display-2">
          {{
            ("error-404-title" | fromDictionary: false) ||
              "Sorry, we konden de pagina niet vinden."
          }}
        </h1>
        <div class="error-404__content  mt-4">
          <a [routerLink]="'/'" class="button button--primary">
            <fstr-icon anchor="line-icon-arrow-left"></fstr-icon>
            <span>Terug naar home</span></a
          >
        </div>

        <h2 class="status-code">{{ code }}</h2>
      </div>
    </article>
  `,
})
export class NotFoundComponent {
  code = 404;

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    @Optional() @Inject(RESPONSE) private response: Response,
    private router: Router
  ) {
    const statusCodeFromUrl = parseInt(this.router.url.replace(/\//g, ""));
    this.code = statusCodeFromUrl ? statusCodeFromUrl : 404;
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      if (this.response) {
        this.response.status(this.code);
      }
    }
  }
}
