import { createSelector } from '@ngrx/store';
import { SocialChannel } from '../../models';
import * as fromFeature from '../reducers';
import * as fromSocialChannels from '../reducers/social-channels.reducer';

export const getSocialsChannelsState = createSelector(
  fromFeature.getSocialMediaState,
  (state: fromFeature.SocialMediaState) => state.channels
);

export const getSocialChannelEntities = createSelector(getSocialsChannelsState, fromSocialChannels.getSocialChannelEntities);
export const getSocialChannelsLoaded = createSelector(getSocialsChannelsState, fromSocialChannels.getSocialChannelsLoaded);
export const getSocialChannelsLoading = createSelector(getSocialsChannelsState, fromSocialChannels.getSocialChannelsLoading);

export const getAllSocialChannels = createSelector(getSocialChannelEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedSocialChannels = createSelector(getAllSocialChannels, (buttons: SocialChannel[]) => {
  return buttons.sort((a, b) => (a.order || 0) - (b.order || 0));
});
