import * as from from './social-channels.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export const featureKey = 'social-media';

export interface SocialMediaState {
  channels: from.SocialChannelsState;
}

export const reducers: ActionReducerMap<SocialMediaState> = {
  channels: from.socialChannelsReducer,
};

export const getSocialMediaState = createFeatureSelector<SocialMediaState>(featureKey);
