import { trigger, transition, style, query as q, group, animate, AnimationMetadata, query, animateChild } from '@angular/animations';

export const routerFade = trigger('fadeAnimation', [
  transition('* <=> *', [
    query(':leave', style({ position: 'fixed', width: '100%', zIndex: 99 }), { optional: true }),
    group([
      query(':enter', [style({ opacity: 0 }), animate('0.25s ease-out', style({ opacity: 1 }))], { optional: true }),
      query(':leave', [animate('0.2s ease-out', style({ opacity: 0 }))], { optional: true }),
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
]);
