import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-partner-list',
  standalone: true,
  templateUrl: './partner-list.component.html',
  styleUrl: './partner-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerListComponent {
  partners = input<{ id: number; title: string; websiteUrl: string; logoUrl: string }[]>();
}
