import { Component, PLATFORM_ID, Renderer2, inject, computed, effect, Inject, Optional, signal, HostListener } from '@angular/core';

import { isPlatformBrowser, DOCUMENT, ViewportScroller, JsonPipe } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, NavigationCancel, NavigationError, Scroll } from '@angular/router';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { filter } from 'rxjs/operators';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieConfigService, CookieNoticeComponent, getCookiePreferences, ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { getRouterState } from './store';
import { FromMenuPipe, getMenuLoaded } from '@teamfoster/sdk/menu-ngrx';
import { getTheme } from './store/selectors/theme.selector';
import { toSignal } from '@angular/core/rxjs-interop';
import { TopbarComponent } from './standalone/components/topbar/topbar.component';
import { FooterComponent } from './standalone/components/footer/footer.component';
import { LoaderIndicatorComponent } from './standalone/components/loading-indicator/loading-indicator.component';
import { GridOverlayComponent } from './standalone/components/grid-overlay/grid-overlay.component';
import { routerFade } from './animations';
import { styleguideConfig } from './styleguide/styleguide-config';
import { getActivePortal } from './portals/store';
import { SocialChannelButtonsComponent } from './social-media/components/social-channel-buttons/social-channel-buttons.component';
import { PartnerListComponent } from './standalone/components/partner-list/partner-list.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterOutlet,
    TopbarComponent,
    FooterComponent,
    LoaderIndicatorComponent,
    CookieNoticeComponent,
    FromDictionaryPipe,
    GridOverlayComponent,
    FromMenuPipe,
    JsonPipe,
    SocialChannelButtonsComponent,
    PartnerListComponent,
  ],
})
export class AppComponent {
  private store = inject(Store);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private viewportScroller = inject(ViewportScroller);
  private platformId = inject(PLATFORM_ID);
  private document: any = inject(DOCUMENT);
  private cookieConfig = inject(CookieConfigService);

  activePortal$ = this.store.selectSignal(getActivePortal);

  routeState$ = this.store.selectSignal(getRouterState);
  menuOpen$ = this.store.selectSignal(navSelectors.getMenuOpen);
  showProgramCTAButton$ = this.store.selectSignal(navSelectors.getShowProgramCTAButton);
  cookiePrefs$ = this.store.selectSignal(getCookiePreferences);
  theme$ = this.store.selectSignal(getTheme);
  themeEffect$ = effect(() => this.updateTheme(this.theme$()));
  dictionaryLoaded$ = this.store.selectSignal(getDictionaryLoaded);
  menuLoaded$ = this.store.selectSignal(getMenuLoaded);

  private routerEvents$ = toSignal(
    this.router.events.pipe(
      filter(e => [NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Scroll].some(b => e instanceof b))
    )
  );
  loading$ = computed(
    () =>
      !(
        this.routerEvents$() instanceof NavigationEnd ||
        this.routerEvents$() instanceof NavigationCancel ||
        this.routerEvents$() instanceof NavigationError ||
        this.routerEvents$() instanceof Scroll
      )
  );

  loaded$ = computed(() => this.dictionaryLoaded$() && this.menuLoaded$());

  domainName = this.document.location.hostname;
  testGridEnabled = false;
  routerAnimationState = '';

  menuOpenEffect$ = effect(() => this.updateRoot(this.menuOpen$()));
  routerEffect$ = effect(() => {
    const event = this.routerEvents$();
    const state = this.routeState$();

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (event instanceof NavigationStart && event.navigationTrigger === 'imperative' && !state?.state?.queryParams) {
      window.scrollTo(0, 0);
    }
    if (event instanceof NavigationEnd && this.cookiePrefs$()?.analytical) {
      if ((<any>window).gtag) {
        (<any>window).gtag('config', this.cookieConfig.analyticsCode, {
          page_path: event.urlAfterRedirects,
          anonymize_ip: true,
        });
      }
    }

    if (event instanceof Scroll) {
      const urlFragment = event.anchor;
      // if we can smooth scroll to the fragment, do it. Otherwise fallback on build in anchor scrolling
      if (event.routerEvent.id > 1 && urlFragment && document.getElementById(urlFragment)) {
        this.smoothScroll(document.getElementById(urlFragment));
        return;
      }
      if (event.anchor) {
        // If anchor exists, scroll to anchor
        this.viewportScroller.scrollToAnchor(event.anchor);
      } else if (event.position) {
        // Scroll to the saved position
        this.viewportScroller.scrollToPosition(event.position);
      } else {
        // Scroll to the top
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    }

    this.document.querySelector('body').classList.add('set--in');
    this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  });

  private previousPath = '';

  @HostListener('keydown.escape', ['$event'])
  esc() {
    this.closeMenu();
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  openCookiePreferences() {
    this.store.dispatch(ShowCookieSettings());
  }

  private updateRoot(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  private updateTheme(theme: string) {
    const bodyClasses: string[] = Array.from(this.document.body.classList);
    const possibleThemes = styleguideConfig['themes'].map((t: string) => 'theme-' + t);
    const prevThemeClass = bodyClasses.find(c => possibleThemes.includes(c));

    if (prevThemeClass) {
      this.renderer.removeClass(this.document.body, prevThemeClass);
    }

    this.renderer.addClass(this.document.body, 'theme-' + theme);
  }

  private smoothScroll(targetElement: HTMLElement | null): void {
    if (!targetElement) {
      return;
    }
    const yOffset = -120; // Your offset value
    const yPosition = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;

    setTimeout(() => {
      window.scrollTo({
        top: yPosition,
        behavior: 'smooth',
      });
    }, 0);
  }
}
