import { trigger, transition, style, animate, group } from '@angular/animations';

export const fade = trigger('fadeAnimation', [
  transition(':enter', [
    // When the element is entering
    style({ opacity: 0 }), // Start with opacity 0
    animate('0.15s ease-out', style({ opacity: 1 })), // Fade to opacity 1
  ]),
  transition(':leave', [
    // When the element is leaving
    animate('0.1s ease-out', style({ opacity: 0 })), // Fade from opacity 1 to 0
  ]),
]);
