import { Component, inject, input, output, computed, ChangeDetectionStrategy, PLATFORM_ID } from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';
import { LinkComponent } from '@teamfoster/sdk/button';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { fromEvent } from 'rxjs';
import { DOCUMENT, isPlatformServer, JsonPipe } from '@angular/common';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { fade } from 'src/app/animations/simple.animation';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  standalone: true,
  styleUrls: ['./topbar.component.scss'],
  animations: [fade],
  imports: [RouterModule, LinkComponent, CdkTrapFocus, FromDictionaryPipe],
})
export class TopbarComponent {
  private router = inject(Router);
  private platformId = inject(PLATFORM_ID);
  private document = inject(DOCUMENT);

  loading = input<boolean>(false);
  menuActive = input<boolean>(false);
  showProgramCTAButton = input<boolean>(false);
  logoClass = input<string>('');
  menu = input<fromMenu.MenuItem[]>([]);
  metaMenu = input<fromMenu.MenuItem[]>([]);

  toggleOverlay = output<void>();
  closeOverlay = output<void>();
  openOverlay = output<void>();
  openCookiePreferences = output<void>();

  scrollUpdate = toSignal(fromEvent(this.document, 'scroll').pipe(debounceTime(5)));

  fixed = computed(() => {
    const e = this.scrollUpdate(); // trigger on scrollUpdate
    return (isPlatformServer(this.platformId) ? 0 : window.scrollY) > 104;
  });

  featuredItems = computed(() => {
    return this.menu().filter(item => item.featured);
  });
  menuItems = computed(() => {
    return this.menu().filter(item => !item.featured);
  });
}
