<footer class="footer section-outset-y container">
  <main class="footer__main">
    <h3>{{ 'footer-titel' | fromDictionary }}</h3>
    <ul class="footer__list portal-list">
      @for (portal of portals; track $index) {
        <li class="portal-list__item">
          <a [href]="portal.url">
            <img
              class="portal-list__item__image"
              [ngSrc]="portal.image.url"
              ngSrcset="200w, 400w"
              [width]="150"
              [height]="50"
              [loaderParams]="{
                width: 150,
                height: 50,
                anchor: 'middlecenter',
                mode: 'max',
              }"
              [alt]="portal.image.alt"
            />
          </a>
        </li>
      }
    </ul>

    <ul class="meta-nav-list flex-column flex-sm-row d-flex align-items-center gap-3 justify-content-center">
      @for (page of menu(); track page) {
        <li class="meta-nav-list__item">
          <fstr-link
            [button]="{
              text: page.title,
              url: page.url || '',
              routerLink: page.routerLink,
              fragment: page.fragment,
            }"
            [routerLinkActive]="'is--active'"
            [routerLinkActiveOptions]="{ exact: page.url === '/' }"
            [showText]="false"
            [cssClasses]="'link-muted'"
          >
            <span>{{ page.title }}</span>
          </fstr-link>
        </li>
      }
      <li class="meta-nav-list__item">
        <button type="button" class="link-muted" (click)="openCookiePreferences.emit()">
          {{ 'cookie-settings' | fromDictionary }}
        </button>
      </li>
    </ul>
  </main>
</footer>
