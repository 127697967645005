import { NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit, inject, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { LinkComponent } from '@teamfoster/sdk/button';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import { link } from 'node:fs';
import { url } from 'node:inspector';
import { ContentPage } from 'src/app/content/models';
import { JsonLdComponent } from 'src/app/seo/components/json-ld/json-ld.component';
import { SocialChannel } from 'src/app/social-media/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  styleUrls: ['./footer.component.scss'],
  imports: [FromDictionaryPipe, JsonLdComponent, LinkComponent, NgOptimizedImage],
})
export class FooterComponent implements OnInit {
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);

  domain = input<string>('');
  menu = input<MenuItem[]>([]);
  socials = input<SocialChannel[]>([]);
  schema = input<any>({
    '@context': 'http://schema.org',
    '@type': this.dict.transform('footer-json-ld-meta-type'),
    'name': this.dict.transform('meta-titel'),
    'url': this.domain,
    'logo': `${this.domain}/assets/gfx/logo.svg`,
    'contactPoint': {
      '@type': 'ContactPoint',
      'email': this.dict.transform('contact-email'),
      'contactType': 'customer service',
    },
    'sameAs': [],
  });

  portals = [
    {
      title: 'Hedon Talent Support',
      image: { url: '/assets/gfx/logos/logo-hedon-talent-support.png', alt: 'Hedon Talent Support' },
      url: 'https://www.hedon-zwolle.nl/talent-support',
    },
    {
      title: 'Hedon Music Club',
      image: { url: '/assets/gfx/logos/logo-hedon-music-club.svg', alt: 'Hedon Music Club' },
      url: 'https://www.hedon-zwolle.nl/talent-support',
    },
    {
      title: 'Buro Ruis',
      image: { url: '/assets/gfx/logos/logo-buro-ruis.svg', alt: 'Buro Ruis' },
      url: 'https://www.hedon-zwolle.nl/talent-support',
    },
    {
      title: 'Bruisweken',
      image: { url: '/assets/gfx/logos/logo-bruisweken.svg', alt: 'Bruisweken' },
      url: 'https://www.hedon-zwolle.nl/talent-support',
    },
    {
      title: 'Beatbox',
      image: { url: '/assets/gfx/logos/logo-beatbox.svg', alt: 'Beatbox' },
      url: 'https://www.hedon-zwolle.nl/talent-support',
    },
  ];

  openCookiePreferences = output<void>();

  year = new Date().getFullYear();

  ngOnInit(): void {
    this.socials().forEach(s => {
      const socialLink = s; // to do config with social buttons
      this.schema().sameAs.push(socialLink);
    });
  }
}
