import { Injectable, Optional, Inject, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocialConfigService } from './social-config.service';
import { SocialChannel, SocialConfig } from '../models';
import { BASE_URL } from '@teamfoster/sdk/ssr';
@Injectable()
export class SocialChannelsService {
  private http = inject(HttpClient);
  private BASE_URL = inject(BASE_URL, { optional: true }) || '';

  public location: { [identifier: string]: Location } = {};

  private get _apiUrl() {
    if (!this.config.apiUrl) return `${this.BASE_URL || ''}api/social-channels`;
    if (this.isAbsolute(this.config.apiUrl)) {
      return this.config.apiUrl;
    }

    return `${this.BASE_URL || ''}${this.config.apiUrl}`;
  }

  constructor(@Inject(SocialConfigService) private config: SocialConfig) {}

  getSocialChannels() {
    return this.http.get<SocialChannel[]>(this._apiUrl);
  }

  init() {}

  isAbsolute(url: string) {
    var pattern = /^https?:\/\//i;
    return pattern.test(url);
  }
}
